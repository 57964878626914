// Dependent colors
$black: #000000;
$blurple-600: #2075b1;
$blurple-500: #2b90d9;
$blurple-400: #56a7e1;
$blurple-300: #82bee9;
$grey-600: hsl(208deg, 8%, 33%);
$grey-100: hsl(208deg, 51%, 90%);

$classic-base-color: #282c37;
$classic-primary-color: #9baec8;
$classic-secondary-color: #d9e1e8;
$classic-highlight-color: #2b90d9;

$ui-base-color: $classic-base-color !default;
$ui-primary-color: $classic-primary-color !default;
$ui-secondary-color: $classic-secondary-color !default;
$ui-highlight-color: $classic-highlight-color !default;

$ui-button-background-color: $blurple-500 !default;
$ui-button-focus-background-color: $blurple-600 !default;
$ui-button-focus-outline-color: $blurple-400 !default;
$ui-button-focus-outline: solid 2px $ui-button-focus-outline-color !default;

$ui-button-secondary-color: $blurple-500 !default;
$ui-button-secondary-border-color: $blurple-500 !default;
$ui-button-secondary-focus-border-color: $blurple-300 !default;
$ui-button-secondary-focus-color: $blurple-300 !default;

$ui-button-tertiary-color: $blurple-300 !default;
$ui-button-tertiary-border-color: $blurple-300 !default;
$ui-button-tertiary-focus-background-color: $blurple-600 !default;

$darker-text-color: lighten($ui-primary-color, 20%) !default;
$dark-text-color: lighten($ui-primary-color, 12%) !default;
$secondary-text-color: lighten($ui-secondary-color, 6%) !default;
$highlight-text-color: lighten($ui-highlight-color, 10%) !default;
$action-button-color: lighten($ui-base-color, 50%) !default;

$inverted-text-color: $black !default;
$lighter-text-color: darken($ui-base-color, 6%) !default;
$light-text-color: darken($ui-primary-color, 40%) !default;
